import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '226, 9, 10',
		'primary-dark': '31, 31, 31',
		'accent': '215, 179, 105',
		'accent-plus': '31, 31, 31',
	},
});
